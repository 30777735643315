<template>
  <div>
    <h4><i class="fa fa-cubes"/> Situação Atual</h4>
    <hr />
    <div class="text-center" v-if="loading">
      <b-spinner /> <br />
      carregando...
    </div>
    <div class="row" v-if="!loading">
      <div class="col-12 col-sm-6">
        <h5><b-icon-house /> Centro de Custo</h5>
        <b-table
          stacked="sm"
          :items="estoqueAtual.estoquePorCusto"
          style="font-size:12px"
          :fields="[
            { key: 'local', label: 'Centro de Custo' },
            { key: 'vl', label: 'Valor' }
          ]"
          small
          hover
          foot-clone
        >
          <template #cell(vl)="row">
            <div class="text-right">
              {{ row.item.vl | currency }}
            </div>
          </template>
          <template #foot(local)>
            Total
          </template>
          <template #foot(vl)>
            <div class="text-right">
              {{ resumos.totalEstoqueAtual | currency }}
            </div>
          </template>
        </b-table>
      </div>
      <div class="col-12 col-sm-6">
        <h5><b-icon-shop /> Empresa</h5>
        <b-table
          stacked="sm"
          hover
          small
          :items="estoqueAtual.estoquePorEmpresa"
          style="font-size:12px"
          :fields="[
            { key: 'local', label: 'Empresa' },
            { key: 'vl', label: 'Valor' }
          ]"
          foot-clone
        >
          <template #cell(vl)="row">
            <div class="text-right">
              {{ row.item.vl | currency }}
            </div>
          </template>
          <template #foot(local)>
            Total
          </template>
          <template #foot(vl)>
            <div class="text-right">
              {{ resumos.totalEstoqueAtual | currency }}
            </div>
          </template>
        </b-table>
      </div>
    </div>

    <h4 class="mt-4"><i class="fa fa-arrows-alt-h"/> Movimentação</h4>
    <hr />
    <div class="row">
      <div class="col-6">
        <h5 class="text-success">
          <i class="fa fa-arrow-up"/> Total de Entradas [R$
          {{ resumos.totalEntradas | currency }}]
        </h5>
        <b-tabs fill pill class="mt-3">
          <b-tab title="Fornecedor">
            <template #title> <b-icon-truck /> Forncedores </template>
            <b-table
              stacked="sm"
              hover
              small
              :items="estoqueAtual.entradasPorFornecedor"
              style="font-size:12px"
              :fields="[
                { key: 'local', label: 'Fornecedor' },
                { key: 'vl', label: 'Valor' }
              ]"
              foot-clone
            >
              <template #cell(vl)="row">
                <div class="text-right">
                  {{ row.item.vl | currency }}
                </div>
              </template>
              <template #foot(local)>
                Total
              </template>
              <template #foot(vl)>
                <div class="text-right">
                  {{ resumos.totalEntradas | currency }}
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Empresa">
            <template #title> <b-icon-shop /> Empresas </template>
            <b-table
              stacked="sm"
              hover
              small
              :items="estoqueAtual.entradasPorEmpresa"
              style="font-size:12px"
              :fields="[
                { key: 'local', label: 'Empresa' },
                { key: 'vl', label: 'Valor' }
              ]"
              foot-clone
            >
              <template #cell(vl)="row">
                <div class="text-right">
                  {{ row.item.vl | currency }}
                </div>
              </template>
              <template #foot(local)>
                Total
              </template>
              <template #foot(vl)>
                <div class="text-right">
                  {{ resumos.totalEntradas | currency }}
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Centro Custo">
            <template #title> <b-icon-house /> Centro de Custo </template>
            <b-table
              stacked="sm"
              hover
              small
              :items="estoqueAtual.entradasPorCusto"
              style="font-size:12px"
              :fields="[
                { key: 'local', label: 'Centro de Custo' },
                { key: 'vl', label: 'Valor' }
              ]"
              foot-clone
            >
              <template #cell(vl)="row">
                <div class="text-right">
                  {{ row.item.vl | currency }}
                </div>
              </template>
              <template #foot(local)>
                Total
              </template>
              <template #foot(vl)>
                <div class="text-right">
                  {{ resumos.totalEntradas | currency }}
                </div>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col-6">
        <h5 class="text-danger">
          <i class="fa fa-arrow-down"/> Total de Saídas [R$ {{ resumos.totalSaidas | currency }}]
        </h5>
        <b-tabs fill pill class="mt-3">
          <b-tab title="Fornecedor">
            <template #title> <b-icon-truck /> Forncedores </template>
            <b-table
              stacked="sm"
              hover
              small
              :items="estoqueAtual.saidasPorFornecedor"
              style="font-size:12px"
              :fields="[
                { key: 'local', label: 'Fornecedor' },
                { key: 'vl', label: 'Valor' }
              ]"
              foot-clone
            >
              <template #cell(vl)="row">
                <div class="text-right">
                  {{ row.item.vl | currency }}
                </div>
              </template>
              <template #foot(local)>
                Total
              </template>
              <template #foot(vl)>
                <div class="text-right">
                  {{ resumos.totalSaidas | currency }}
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Empresa">
            <template #title> <b-icon-shop /> Empresas </template>
            <b-table
              stacked="sm"
              hover
              small
              :items="estoqueAtual.saidasPorEmpresa"
              style="font-size:12px"
              :fields="[
                { key: 'local', label: 'Empresa' },
                { key: 'vl', label: 'Valor' }
              ]"
              foot-clone
            >
              <template #cell(vl)="row">
                <div class="text-right">
                  {{ row.item.vl | currency }}
                </div>
              </template>
              <template #foot(local)>
                Total
              </template>
              <template #foot(vl)>
                <div class="text-right">
                  {{ resumos.totalSaidas | currency }}
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Centro Custo">
            <template #title> <b-icon-house /> Centro de Custo </template>
            <b-table
              stacked="sm"
              hover
              small
              :items="estoqueAtual.saidasPorCusto"
              style="font-size:12px"
              :fields="[
                { key: 'local', label: 'Centro de Custo' },
                { key: 'vl', label: 'Valor' }
              ]"
              foot-clone
            >
              <template #cell(vl)="row">
                <div class="text-right">
                  {{ row.item.vl | currency }}
                </div>
              </template>
              <template #foot(local)>
                Total
              </template>
              <template #foot(vl)>
                <div class="text-right">
                  {{ resumos.totalSaidas | currency }}
                </div>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import EstoqueLib from "../../libs/EstoqueLib";
import moment from "moment";
export default {
  components: {},
  props: {
    data: Object,
    empresas: Array
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      estoqueAtual: {},
      loading: false,
      resumos: {
        totalEntradas: 0,
        totalSaidas: 0,
        totalEstoqueAtual: 0
      }
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.carregar();
      }
    },
    empresas: {
      deep: true,
      handler() {
        this.carregar();
      }
    }
  },
  computed: {},
  methods: {
    async carregar() {
      this.loading = true;
      this.estoqueAtual = await EstoqueLib.getResumoAtual({
        dt_i: moment(this.data.start).format("YYYY-MM-DD"),
        dt_f: moment(this.data.end).format("YYYY-MM-DD"),
        empresas:
          this.empresas && this.empresas.length > 0
            ? this.empresas.map(r => {
                return r.cod_empresa;
              })
            : []
      });
      this.resumos.totalEstoqueAtual = await this.estoqueAtual.estoquePorCusto.reduce(
        (ret, vl) => {
          ret += vl.vl;
          return ret;
        },
        0
      );
      this.resumos.totalEntradas = await this.estoqueAtual.entradasPorEmpresa.reduce(
        (ret, vl) => {
          ret += vl.vl;
          return ret;
        },
        0
      );
      this.resumos.totalSaidas = await this.estoqueAtual.saidasPorEmpresa.reduce(
        (ret, vl) => {
          ret += vl.vl;
          return ret;
        },
        0
      );
      console.log("estt->", this.estoqueAtual);
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>